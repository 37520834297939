<template>
  <v-app-bar
    app
    absolute
    clipped-left
    color="white"
    elevation="0"
    style="padding: 0px 10vw 0px 10vw; border-bottom: solid 1px #00000026 !important"
  >
    <!-- <v-app-bar-nav-icon
      @click="drawer"
    /> -->
    <div
      class="azure-text logo-clickable"
      style="font-size: 2rem; letter-spacing: -1px;"
      @click="onLogoClick"
    >
      <!-- <span
        style="font: italic normal normal 34px/45px Roboto"
      >
        Fast
      </span>
      <span
        style="font: bold normal normal 34px/45px Roboto"
      >
      ER
      </span> -->
      <!-- <v-img src="@/assets/Eastman_FastER_Graphic.svg" max-width="180"></v-img> -->
      <FastERLogo class="applogo" />
    </div>
    <v-spacer/>
    <div
      v-if="isAuthenticated"
    >
      <v-menu
        offset-y
        nudge-bottom="14px"
        rounded="t-0 b-md"
        content-class="elevation-0"
      >
        <template v-slot:activator="{ on, attrs }">
          <div style="cursor: pointer; user-select: none;" v-on="on" v-bind="attrs">
            <v-avatar
              size="36"
              class="mr-2"
              color="var(--eastman-azure)"
            >
              <img
                v-if="profileImage"
                :src="`data:image/png;base64,${profileImage}`"
              >
              <v-icon
                v-else
                dark
              >
                mdi-account
              </v-icon>
            </v-avatar>
            <span>
              {{ (currentUserInfo && currentUserInfo.displayName) || 'user' }}
            </span>
            <v-icon>
              mdi-chevron-down
            </v-icon>
          </div>
        </template>
        <v-list>
          <v-list-item
            @click="onLogOutClick"
            style="color: var(--eastman-azure) !important"
          >
            Sign out
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div
      v-else
    >
      <router-link
        class="mr-8 app-link app-link-dark"
        active-class="app-link-dark-active"
        to="/register"
      >
        {{ $t('AppRegisterBtn')}}
      </router-link>
      <span
        class="app-link app-link-dark"
        @click="onLogInClick()"
      >
        {{ $t('AppLoginBtn') }}
      </span>
    </div>
  </v-app-bar>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'
import FastERLogo from '@/assets/Eastman_FastER_Graphic.svg'

export default {
  name: 'AppBar',
  components: {
    FastERLogo,
  },
  data: function() {
    return {
      appName: process.env.VUE_APP_NAME,
    }
  },
  computed: {
    ...mapState(['currentUserInfo', 'profileImage']),
    isAuthenticated: function () {
      return this.$msal.isAuthenticated()
    },
  },
  methods: {
    drawer () {
      store.dispatch({type: 'toggleDrawer'})
    },
    onLogInClick () {
      this.$msal.signIn()
    },
    onLogOutClick () {
      // including this shows the name changing to default (currently 'user') before logout
      // because of that I have left it out for now
      //mthis.$store.dispatch('clearUserData')
      this.$msal.signOut()
    },
    onLogoClick () {
      if (!this.isAuthenticated && this.$router.currentRoute.path !== '/')
      {
        this.$router.push('/')
      }
      else if (this.isAuthenticated && this.$router.currentRoute.path !== '/coatings/predict')
      {
        this.$router.push('/coatings/predict')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}

.logo-clickable {
  cursor: pointer;
  user-select: none;
  display: flex;
}

.applogo {
  width: 210px;
  height: 45px;
  fill: #636569;
}

</style>
